import ApplicationController from "./application_controller";
import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";

export default class extends ApplicationController {
  static values = {
    url: String,
    clickable: Boolean,
    multiple: Boolean,
    boardId: String
  };
  static classes = ["inactive"];
  static targets = ["dropzone"];

  connect() {
    super.connect();

    this.dropzone = new Dropzone(document.body, {
      autoQueue: false, // <- this is important!
      url: this.urlValue,
      clickable: this.clickableValue,
      uploadMultiple: this.multipleValue,
      previewsContainer: false,
      drop: event => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        let i = 0;

        Array.from(files).forEach(async file => {
          const uploader = new Uploader(file, this.urlValue);
          uploader.process((error, blob) => {
            if (error) {
              // Handle the error
            } else {
              const form_id = '#new_document_'+i;
              const form = document.querySelector(form_id);
              const field_id = '#document_file_'+i;
              const hiddenFileField = form.querySelector(field_id);

              hiddenFileField.setAttribute("type", "hidden");
              hiddenFileField.setAttribute("value", blob.signed_id);

              this.stimulate("Document#submit", form);
              i += 1;
            }
          });
          this.deactivate();
        });
      }
    });
  }

  disconnect() {
    this.dropzone.destroy();
  }

  activate(e) {
    // code to activate dropzone overlay
  }

  deactivate(e) {
    // code to deactivate dropzone overlay
  }
}


class Uploader {
  constructor(file, url) {
    this.upload = new DirectUpload(file, url, this);
  }

  process(callback) {
    this.upload.create(callback);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event =>
      this.directUploadDidProgress(event)
    );
  }

  directUploadDidProgress(event) {
    // display progress somehow
  }
}
